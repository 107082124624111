import React, { useState } from "react";
import { AiFillPlayCircle } from "@react-icons/all-files/ai/AiFillPlayCircle";

import ReactImageVideoLightbox from "react-image-video-lightbox";

export default function Video({ url }) {
  const [show, setShow] = useState(false);

  let youTubeId = "";

  try {
    youTubeId = url.match(
      /(?:https?:\/\/)?(?:www\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\/?\?v=|\/embed\/|\/)([^\s&]+)/
    )[1];
  } catch {
    console.log("failed to create youtube ID");
  }

  return (
    <>
      <div
        className="video-pg"
        style={{
          background:
            "  linear-gradient(to right, rgba(255, 255, 255, 0.40) 0, rgba(255, 255, 255, 0.40) 100%),url(https://i.ytimg.com/vi_webp/" +
            youTubeId +
            "/maxresdefault.webp)",
          backgroundRepeat: "repeat, no-repeat",
          backgroundSize: "auto, cover",
          backgroundPosition: "center,center",
        }}
      >
        <div className="play-pg" onClick={() => setShow(true)}>
          <AiFillPlayCircle />
        </div>
      </div>

      {show && (
        <ReactImageVideoLightbox
          data={[
            {
              url: "https://www.youtube.com/embed/" + youTubeId,
              type: "video",
              altTag: "video",
            },
          ]}
          startIndex={0}
          showResourceCount={false}
          onCloseCallback={() => setShow(false)}
        />
      )}
    </>
  );
}
