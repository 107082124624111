import React from "react";
import Layout from "../../components/Layout";
import { FaAngleRight } from "@react-icons/all-files/fa/FaAngleRight";

import { Link, graphql } from "gatsby";
import Video from "../../components/Video";

import BackgroundImage from "gatsby-background-image";
import Accordion from "../../components/Accordion";

const ComponentName = ({ data }) => {
  const daa = data.strapiServices;
  const servicesList = data.allStrapiServices.nodes;
  const ProductsList = data.allStrapiProducts.nodes;
  console.log(daa.youtubeLink);
  return (
    <Layout title={data.strapiServices.title}>
      <div className="services">
        <BackgroundImage
          backgroundColor={`#0080FF`}
          fluid={daa.image.localFile.childImageSharp.fluid}
          className="hero"
        >
          <div className="overlay">
            <h1>{daa.title}</h1>
          </div>
        </BackgroundImage>

        <div className="ssbody wrapper_max">
          <div className="naviBody">
            <div className="navigation">
              <h3>Our Services</h3>
              <div className="list">
                {servicesList.map((v, k) => (
                  <Link
                    key={k}
                    activeClassName="active"
                    to={"/services/" + v.slug}
                  >
                    <div key={k}>
                      <p>{v.title}</p>
                      <FaAngleRight />
                    </div>
                  </Link>
                ))}
              </div>
            </div>

            <div className="navigation">
              <h3>Our Products</h3>
              <div className="list list2">
                {ProductsList.map((v, k) => (
                  <Link
                    key={k}
                    activeClassName="active"
                    to={"/products/" + v.slug}
                  >
                    <div key={k}>
                      <p>{v.title}</p>
                      <FaAngleRight />
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>

          <div className="content">
            <h2>{daa.title}</h2>
            <div
              className="page-body"
              dangerouslySetInnerHTML={{ __html: daa.body }}
            />
            <Accordion data={daa.section} />
          </div>
        </div>
      </div>

      {daa.youtubeLink != null &&
        daa.youtubeLink != "null" &&
        daa.youtubeLink != "" && <Video url={daa.youtubeLink} />}
    </Layout>
  );
};

export const query = graphql`
  query ServicesQuery($slug: String!) {
    strapiServices(slug: { eq: $slug }) {
      id
      slug
      title
      body
      titleHindi
      section {
        body
        title
      }
      youtubeLink
      image {
        localFile {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1900) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    allStrapiServices {
      nodes {
        slug
        title
      }
    }
    allStrapiProducts {
      nodes {
        slug
        title
      }
    }
  }
`;

export default ComponentName;
